@keyframes postExpansionA {
  0% {
    transform: scale(0.8) translate(1, 1);
  }
  100% {
    transform: scale(0.8) translate(8px, 8px);
  }
}

@keyframes postExpansionB {
  0% {
    transform: scale(0.8) translate(0, 0);
  }
  100% {
    transform: scale(0.8) translate(-8px, -8px);
  }
}

.post__expansion-button--expanded {
  #postExpansionA {
    animation: postExpansionA 0.4s forwards;
    stroke-width: 2;
  }

  #postExpansionB {
    animation: postExpansionB 0.4s forwards;
    stroke-width: 2;
  }
}

.post--not-expandable {
  .post__expansion-button {
    opacity: 0.1;
    pointer-events: none;
  }
  .card__content {
    mask-image: unset;
    &:hover {
      cursor: default;
    }
  }
}

.post--deleting {
  opacity: 0.6;
}
