@import "~/src/scss/breakpoints";

.navbar__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  overflow: visible;
}

.navbar__container .navbar__item {
  margin-left: var(--spacing-m);
  height: 36px;
  overflow: visible;
  padding: 2px;

  svg {
    width: 37px !important;
    height: 37px !important;
    stroke: rgba(var(--color-dominant-base), 0.2);
    stroke-width: 2px !important;
    fill: var(--color-text);
    overflow: visible;

    &.icon--devmode {
      width: 44px !important;
      height: 44px !important;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.navbar__item--text-icon {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  @media (max-width: $breakpoint-small) {
    display: none;
  }
}

.navbar__item--community {
  display: flex;
  font-weight: bold;
  overflow-wrap: initial;

  svg {
    margin-right: var(--spacing-s);
  }

  &:hover {
    cursor: pointer;
  }
}

.navbar__item--settings {
  transition: transform 0.8s;

  &:hover {
    cursor: pointer;
  }

  &.navbar__item--settings-active {
    transform: rotate(90deg);
  }
}

.navbar__container .navbar__item .user__avatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: var(--color-button-background);
  border: 2px solid var(--color-button-background);
  outline: 2px solid rgba(var(--color-dominant-base), 0.2);
}
