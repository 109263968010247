.shell-meta {
  position: sticky;
  top: calc(var(--header-height) + var(--spacing-s));
  margin-left: var(--spacing-m);
}

.shell-meta__card {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 3px solid var(--color-text);
  border-radius: var(--border-radius);
  padding: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  background-color: var(--color-card-background);
  backdrop-filter: blur(10px);

  transition: height 0.4s;
  max-height: 1000px;
  box-sizing: border-box;

  h2 {
    margin-top: 0;
    margin-bottom: var(--spacing-m);
    text-align: center;
  }

  h3 {
    font-size: medium;
    text-decoration: underline;
  }

  p {
    font-size: small;
  }

  ol {
    padding-left: 0;
    margin-left: 0;
  }
  li {
    font-size: small;
    list-style-position: inside;
    margin-top: var(--spacing-xs);
  }

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }

  button {
    margin-top: var(--spacing-m);
  }
}

.shell-meta__card--minimal {
  flex-flow: row;
  align-items: center;
  padding: var(--spacing-s);

  img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
    order: 0;
    margin-right: var(--spacing-s);
  }
  h2 {
    order: 1;
    font-size: small;
    margin: 0;
    margin-bottom: var(--spacing-xxs);
    margin-right: var(--spacing-s);
    text-align: left;
  }
  button {
    order: 2;
    margin: 0;
    margin-left: auto;
  }
  p {
    display: none;
  }
}

.shell-meta__favorites {
  width: 100%;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-left: 0px; // padding-inline-start 40px...
}
