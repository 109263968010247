.shell-page__header {
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.post__types {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-l);
}

.post__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-button-background);
  color: rgba(var(--color-dominant-base), 1);
  font-family: var(--font-stylized);
  font-size: x-large;
  letter-spacing: var(--spacing-xxs);
  border-radius: var(--border-radius);
  border: none;
  box-shadow: -2px -2px 0 rgba(var(--color-dominant-base), 0.2), 2px -2px 0 rgba(var(--color-dominant-base), 0.2),
    -2px 2px 0 rgba(var(--color-dominant-base), 0.2), 2px 2px 0 rgba(var(--color-dominant-base), 0.2);

  transition: background 0.4s, color 0.4s, box-shadow 0.4s;

  &:hover {
    cursor: pointer;
    background: var(--color-button-hover);
    box-shadow: -1px -1px 0 rgba(var(--color-dominant-base), 0.2), 1px -1px 0 rgba(var(--color-dominant-base), 0.2),
      -1px 1px 0 rgba(var(--color-dominant-base), 0.2), 1px 1px 0 rgba(var(--color-dominant-base), 0.2);
  }
}

.post__button--icon {
  width: 2.4rem;
  height: 2.4rem;
  padding: var(--spacing-s);
  margin-right: var(--spacing-l);

  .icon {
    stroke-width: 0;
    fill: rgba(var(--color-dominant-base), 1);
    transition: fill 0.4s;
  }
  .icon--live {
    stroke-width: 1;
    stroke: rgba(var(--color-dominant-base), 1);
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    .icon {
      fill: var(--color-button-text);
    }
  }
}

.post__button--main {
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-xs) var(--spacing-l);
  margin-bottom: var(--spacing-l);
}
