.replies {
  height: 100%;
  padding-bottom: var(--spacing-m);
  box-sizing: border-box;
  mask-image: -webkit-gradient(linear, left 85%, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));

  .comment {
    margin-top: 2px;
    margin-bottom: var(--spacing-m);
  }
}

.replies__back {
  font-size: large;
  font-weight: bold;
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-l);

  &:hover {
    cursor: pointer;
    color: rgba(var(--color-text-base), 0.7);
  }
}

.replies__wrapper {
  overflow: scroll;
  padding-right: var(--spacing-m);
  height: calc(100% - 100px);
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-card-text);
  }

  &::-webkit-scrollbar-corner {
    background: unset;
  }
}

.replies__main {
}

.replies__list {
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-s);
  padding-top: var(--spacing-s);
  padding-left: var(--spacing-m);
  padding-bottom: var(--spacing-m);
  // padding-right:var(--spacing-m);

  border-left: 2px solid rgba(var(--color-text-base), 0.1);

  &:after {
    content: "";
    display: block;
    height: var(--spacing-m);
  }
}

.replies__load-more {
  margin-top: var(--spacing-l);
  cursor: pointer;

  background: var(--color-card-button-text);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-card-text);
  padding: var(--spacing-xxs) var(--spacing-l);
  color: var(--color-card-text);
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
}
