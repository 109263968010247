.shell-page__content {
  display: flex;
  flex-direction: row;
}

.shell-page__content__main {
  flex: 3;
  transition: width 0.4s;
}

.shell-page__content__main--home {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.shell-page__content__meta {
  flex: 1;
}
