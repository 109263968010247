.shell-preview__wrapper {
  position: absolute;
  padding: 0 5px 2px 5px;
  animation: delay 0.6s ease-in-out forwards;
}

.shell-preview {
  width: 280px;
  background: var(--color-button-background);
  color: var(--color-button-text);
  border: 3px solid var(--color-button-text);
  border-radius: 14px;
  padding: var(--spacing-s);
  box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.56);
}

.shell-preview__header {
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    img {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      margin-right: var(--spacing-s);
    }
    label {
      font-size: small;
      font-weight: bold;
      color: var(--color-card-button-text);
    }
    &:hover {
      cursor: pointer;
      label {
        color: rgba(var(--color-background-base), 0.7);
      }
    }
  }

  button {
    text-transform: uppercase;
    font-weight: bold;
    font-size: small;
    border: 2px solid var(--color-card-text);
    border-radius: var(--border-radius);
    padding: var(--spacing-xxs) var(--spacing-m);
    margin-left: auto;
    background-color: var(--color-card-button-text);
    color: var(--color-card-button-background);
    &:hover {
      cursor: pointer;
    }
  }
}

.shell-preview__content {
  p {
    font-size: small;
    margin-bottom: 0;
  }
}

@keyframes delay {
  0% {
    display: none;
  }
  90% {
    display: none;
  }
  100% {
    display: block;
  }
}
