.comments {
  position: relative;
  display: flex;
  flex-flow: column;
  margin-top: var(--spacing-l);
  height: calc(100% - 120px);
  // height:100%;
  max-height: 100%;
  box-sizing: border-box;
  // flex: 1;
  mask-image: -webkit-gradient(linear, left 85%, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
  padding-bottom: var(--spacing-m);

  h3 {
    padding-top: -100px;
    z-index: 9999;
    span {
      color: #a8a8a8;
      margin-left: var(--spacing-m);
    }
  }
}

.comments__header {
  display: flex;
  justify-content: space-between;
}

.comments__container {
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: var(--spacing-m);

  &:after {
    content: "";
    display: block;
    height: var(--spacing-m);
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-card-text);
  }

  &::-webkit-scrollbar-corner {
    background: unset;
  }
}

.comments__load-more {
  margin-top: var(--spacing-l);
  cursor: pointer;

  background: var(--color-card-button-text);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-card-text);
  padding: var(--spacing-xxs) var(--spacing-l);
  color: var(--color-card-text);
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
}
