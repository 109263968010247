@import "~/src/scss/breakpoints";

.dialog__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: var(--header-height);
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  z-index: 999;
  opacity: 1;
  box-sizing: border-box;

  @media (min-width: $breakpoint-medium) {
    padding-bottom: var(--dock-height);
  }
  @media (max-width: $breakpoint-medium) {
    padding: var(--spacing-xs);
  }
}

.dialog__container--no-blur {
  backdrop-filter: unset;
}

.dialog__content {
  background: rgba(var(--color-card-background-base), 0.95);
  width: 0;
  max-width: 0;
  margin: auto;
  border-radius: var(--border-radius);

  backdrop-filter: blur(10px);
  width: 100%;
  max-width: 1200px;
  height: 100%;
  transform: scale(0);
  opacity: 0;
  animation: open-dialog 0.4s ease-in-out forwards;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-card-text);
  }

  &::-webkit-scrollbar-corner {
    background: unset;
  }
}

.dialog__content--open {
  opacity: 1;
  transform: scale(1);
}

@keyframes open-dialog {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  75% {
    transform: translateY(-3%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
