.upload-image {
  display: flex;
  border-radius: var(--border-radius);
}

.upload-image__preview {
  height: 124px;
  aspect-ratio: 16/9;
  border: dashed;
  box-sizing: border-box;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: var(--border-radius);
  }
}

.upload-image__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: var(--spacing-m);
  max-height: 100%;
  box-sizing: border-box;
}

.upload-image__file-mode {
  display: flex;
  padding: var(--spacing-s);
  height: 100%;
  box-sizing: border-box;
  background: white;
  color: black;

  .file-mode-info {
    flex-grow: 1;

    .file-mode-info__name {
      opacity: 0.8;
    }

    .file-mode-info__txid {
      a {
        color: orange;
        font-size: small;
      }
    }

    .file-mode-info__fail {
      color: #ff9999;
    }
  }

  .upload-image__browse {
    display: flex;
    flex-direction: column;

    button {
      text-transform: uppercase;
      font-weight: bold;
      font-size: small;
      border: 2px solid black;
      border-radius: var(--border-radius);
      padding: var(--spacing-xs) var(--spacing-m);
      margin-left: auto;
      margin-bottom: var(--spacing-xs);
      background-color: black;
      color: white;
      width: 200px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        cursor: pointer;
        background-color: white;
        color: black;
      }
    }
  }
}

.upload-image__url-mode {
  padding: var(--spacing-s);
  height: 100%;
  box-sizing: border-box;
  background: white;
  color: black;

  div {
    opacity: 0.8;
  }

  input {
    height: 4ch;
    width: 100%;
    box-sizing: border-box;
    background-color: black;
  }
}

.upload-image__tab-section {
  display: flex;

  .upload-image__tab {
    font-size: large;
    padding: var(--spacing-xs) var(--spacing-s);
    font-weight: bold;
    opacity: 0.6;
    background-color: white;
    color: black;

    // • Equal-width tab
    width: 12ch; // max("Upload File"|"Enter URL"). TODO: This method won't work if localized.
    text-align: center;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .upload-image__tab--on {
    opacity: 1;
    &:hover {
      cursor: default;
      opacity: 1;
    }
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
