.spinner {
  height: 40px;
}

.spinner--small {
  height: 10px;
}

.spinner--dark {
  .rect {
    background-color: white;
  }
}

.spinner--light {
  .rect {
    background-color: black;
  }
}

.spinner--pad-small {
  padding: var(--spacing-s);
}

.spinner--pad-large {
  padding: var(--spacing-l);
}

// ****************************************************************************
// pulse-spinner
// ****************************************************************************

@keyframes stretchDelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }
}

.pulse-spinner {
  height: 100%;
  margin: 0 auto;
  width: 50px;

  .rect {
    width: 6px;
    height: 100%;

    animation: stretchDelay 1.2s infinite ease-in-out;
    display: inline-block;
    margin: 0 2px;

    &.rect2 {
      animation-delay: -1.1s;
    }

    &.rect3 {
      animation-delay: -1s;
    }

    &.rect4 {
      animation-delay: -0.9s;
    }

    &.rect5 {
      animation-delay: -0.8s;
    }
  }
}
