@import "~/src/scss/breakpoints";

.playground-container {
  position: fixed;
  left: 20px;
  top: 50%;
  width: 200px;
  background: rgba(255, 0, 0, 0.5);
  border: 3px solid red;
  color: white;
  border-radius: var(--border-radius);
  padding: var(--spacing-m);
  h4 {
    margin-top: 0;
  }

  label {
    display: block;
    font-weight: bold;
  }

  select {
    width: 100%;
    border-radius: var(--border-radius);
    height: 2rem;
    padding: 0 var(--spacing-xs);
  }

  @media (max-width: $breakpoint-medium) {
    display: none;
  }
}

.playground-container--closed {
  width: 10px;
  height: 10px;
  letter-spacing: 4rem;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0.2;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}
