@import "~/src/scss/breakpoints";

.post {
  position: relative;
  margin-bottom: var(--spacing-l);
}

.post__container {
  margin-top: -44px;
  display: flex;
  flex-direction: row;
  max-height: 650px;
  max-width: 100%;
  padding: var(--spacing-l) var(--spacing-xl);
  border: 3px solid var(--color-text);
  border-radius: var(--border-radius);
  color: var(--color-card-text);
  background: var(--color-card-background);
  backdrop-filter: blur(10px);
  overflow: hidden;
  transition: padding 0.2s, left 0.4s, top 0.4s, right 0.4s, bottom 0.4s, max-height 0.4s;

  a {
    color: var(--color-card-text);
    text-decoration: none;
  }

  @media (max-width: $breakpoint-small) {
    flex-direction: column;
    padding: var(--spacing-m) var(--spacing-s);
    padding-bottom: var(--spacing-xl);
    max-height: unset;
    margin-top: unset;
  }
}

.post__expansion-button {
  position: sticky;
  top: 98px;
  margin-top: var(--spacing-s);
  margin-left: var(--spacing-s);
  margin-bottom: var(--spacing-s);
  background: var(--color-card-text);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 1;
  svg {
    stroke: transparent;
    fill: var(--color-card-button-text);
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: $breakpoint-small) {
    position: absolute;
    top: unset;
    right: var(--spacing-s);
    bottom: 3px;
  }
}

.post__container--expanded {
  .card__content {
    visibility: visible;
    mask-image: unset;
    cursor: default !important;
  }
}

.post__background {
  right: unset;
  bottom: unset;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0px);
  transition: background-color 0.4s, backdrop-filter 0.4s;
}

.post__background--expanded {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  transition: background-color 0.4s, backdrop-filter 0.4s;
}

.post__content {
  display: flex;
  flex-direction: row;
  flex: 1.5;
  transition: flex 0.4s;
  max-height: 100%;
}

.card {
  width: 100%;
}

.card__header {
  display: flex;
  flex-flow: row;

  @media (min-width: $breakpoint-small) {
    margin-bottom: var(--spacing-m);
  }
  @media (max-width: $breakpoint-small) {
    margin-bottom: var(--spacing-s);
  }
}

.card__shell-thumb {
  width: calc(2.5em - 6px);
  height: calc(2.5em - 6px);
  border-radius: 50%;
  margin-right: var(--spacing-s);
  border: 3px solid var(--color-card-text);
  background-color: var(--color-card-text);
  &:hover {
    border: 3px solid rgba(var(--color-text-base), 0.7);
  }
}

.card__shell-thumb-user {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-left: -1.5rem;
  margin-right: 0.5rem;
  margin-top: 1.4rem;
  border: 1px solid var(--color-card-text);
  background-color: var(--color-card-text);
  &:hover {
    border: 1px solid rgba(var(--color-text-base), 0.7);
  }
}

.card__shell-name {
  display: inline-block;
  font-weight: bold;
  font-size: small;
  align-self: flex-start;

  span {
    color: #a8a8a8;
  }
}

.card__shell-date {
  font-size: x-small;
  color: #a8a8a8;
}

.card__shell-menu {
  margin-left: auto;
  .icon {
    width: 1rem;
    height: 1rem;
    overflow: visible;
    stroke: var(--color-card-text);
    border-radius: 50%;
    padding: 4px;

    &:hover,
    &[active="true"] {
      cursor: pointer;
      background-color: var(--color-card-text);
      stroke: var(--color-card-button-text);
    }

    @media (min-width: $breakpoint-small) {
      margin-right: 10px;
    }
  }
}

.card__content {
  overflow-wrap: anywhere;
  mask-image: -webkit-gradient(linear, left 85%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));

  .card__preview {
    display: flex;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-card-text);
  }

  &::-webkit-scrollbar-corner {
    background: unset;
  }

  .card__thumbnail {
    width: 100%;
    @media (min-width: $breakpoint-small) {
      margin-right: var(--spacing-l);
    }
  }

  img {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: var(--border-radius);
  }

  .iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: calc(9 / 16 * 100%);

    iframe,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: unset;
      aspect-ratio: 16/9;
      border-radius: var(--border-radius);
      border-width: 0;
    }
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: $breakpoint-small) {
    padding-right: 0;
  }
}

.card__text {
  max-height: 40px;
}

.card__reactions {
  display: flex;
  font-weight: bold;

  .card__reaction--icon {
    display: flex;
    justify-content: center;
    .icon {
      overflow: visible;
      fill: var(--color-card-text);
      stroke: transparent;
      margin-bottom: 2px;

      &:hover {
        cursor: pointer;
        fill: red;
      }
    }
  }

  .card__reaction--total {
    display: flex;
    justify-content: center;
  }

  @media (min-width: $breakpoint-small) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 50px;
    margin-bottom: 76px;
    color: #bdbdbd;

    .card__reaction--total {
      font-size: 15px;
      margin-bottom: 6px;
    }
  }

  @media (max-width: $breakpoint-small) {
    position: absolute;
    bottom: var(--spacing-s);
    color: var(--color-card-button-text);
    font-size: small;
    margin-top: var(--spacing-s);
    .card__reaction-group {
      display: flex;
      background-color: var(--color-card-button-background);
      border-radius: 15px;
      padding: var(--spacing-xxs) var(--spacing-xs);
      margin-right: var(--spacing-xs);

      .card__reaction--icon {
        .icon {
          height: 14px;
          fill: var(--color-card-button-text);

          &.icon--comments {
            margin-right: var(--spacing-xxs);
          }
        }
      }

      .card__reaction--total {
        padding: 0 var(--spacing-xxs);
      }
    }
  }
}

.post__comments {
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 1;
  margin-left: var(--spacing-l);
  transition: margin 0.2s;
  min-height: 420px;
  max-height: 100%;
  overflow: hidden;

  @media (max-width: $breakpoint-small) {
    display: none;
  }
}

.post__meta {
  display: flex;
  margin-left: auto;
}

.post__label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: small;
  border: 2px solid var(--color-card-text);
  border-radius: var(--border-radius);
  padding: var(--spacing-xxs) var(--spacing-m);
  margin-bottom: var(--spacing-s);
  margin-right: var(--spacing-s);
}

.post__shell {
  // TODO: do the black bg thing. Not sure hwo to do that while respecting dark theme/wallpaper
  text-transform: uppercase;
  font-weight: bold;
  font-size: small;
  border: 2px solid var(--color-card-text);
  border-radius: var(--border-radius);
  padding: var(--spacing-xxs) var(--spacing-m);
  margin-bottom: var(--spacing-s);
}

.comments-windows {
  position: absolute;
  top: var(--spacing-l);
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  transition: margin-left 0.4s;
  .comments-window {
    &:first-of-type {
      flex: 0 0 100%;
    }
    &:last-of-type {
      flex: 0 0 100%;
    }
  }
}
