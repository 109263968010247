@import "~/src/scss/breakpoints";

.shell-form__container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  padding: 0 var(--spacing-l);
  border: 3px solid var(--color-card-text);
  background: black;
  color: white;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: white;
  }

  &::-webkit-scrollbar-corner {
    background: unset;
  }

  input[type="text"] {
    border: 3px solid var(--color-text);
    padding: var(--spacing-s) var(--spacing-s);
    width: 100%;
    box-sizing: border-box;
  }

  textarea {
    height: 300px;
  }
}

.shell-form__body {
  margin: var(--spacing-xxl);
  flex-grow: 1;
}

.shell-form__body--center {
  margin: var(--spacing-xxl);
  text-align: center;
}

.shell-form__subtitle {
  margin-top: var(--spacing-xxs);
}

.shell-form__group {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: var(--spacing-l);

  label {
    min-width: 140px;
    font-size: large;
    text-align: left;
  }
}

.shell-form__header {
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  background: black;
  height: 50px;
  padding-top: var(--spacing-l);
  h1 {
    color: white;
    margin-top: 0;
    text-shadow: unset;
  }
  img {
    position: absolute;
    top: calc(var(--spacing-m) + var(--spacing-s));
    right: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
  }
}

.shell-form__value-group {
  display: flex;
  flex-direction: column;
}

.shell-form__value {
  margin-bottom: var(--spacing-m);
  .icon {
    fill: white;
    stroke: white;
    margin-bottom: -4px;
    margin-left: var(--spacing-l);
    margin-right: calc(var(--spacing-l) - var(--spacing-s));
    overflow: visible;
  }
}

.shell-form__nsfw {
  margin-left: var(--spacing-xxl);
}

.shell-form__actions {
  display: flex;
  justify-content: center;

  .button__primary {
    text-transform: uppercase;
    font-size: x-large;
    border: 2px solid white;
    border-radius: var(--border-radius);
    padding: var(--spacing-xxs) var(--spacing-m);
    margin-bottom: var(--spacing-s);
    background: black;
    color: white;
    width: 200px;

    &:hover {
      cursor: pointer;
    }
  }
  .button__secondary {
    text-transform: unset;
    font-size: x-large;
    border: none;
    border-radius: var(--border-radius);
    padding: var(--spacing-xxs) var(--spacing-m);
    margin-bottom: var(--spacing-s);
    background: black;
    color: white;
    width: 200px;

    &:hover {
      cursor: pointer;
    }
  }
}
