@import "~/src/scss/breakpoints";

.wallet-connect__container {
  button {
    padding: 0;
    max-height: 36px !important;
    border-radius: 38px !important;
    outline: 2px solid rgba(var(--color-dominant-base), 0.2);
    transition: outline 0.4s;

    &:hover {
      transform: unset !important;
      background: var(--color-button-background);
      color: var(--color-button-text);
    }

    &.mocked-styled-1 {
      padding-right: 0;
      color: var(--color-button-text);
      background-color: var(--color-button-background);
    }

    .mocked-styled-3 {
      border-radius: 24px;
    }

    @media (max-width: $breakpoint-small) {
      &.mocked-styled-1 {
        padding: 0;
      }
      .mocked-styled-3 {
        width: 36px;
        overflow: hidden;
        padding: 0;
        img {
          width: 100%;
          height: calc(100% - 4px);
          margin: 0;
        }
      }
      .mocked-styled-4 {
        display: none;
      }
    }
  }
}
