.posts {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: var(--spacing-s);
  transition: grid-template-columns 0.3s ease;
  padding-bottom: var(--spacing-xxl);
}

.posts--tiles {
  grid-template-columns: repeat(4, 1fr);
  .post {
    margin-bottom: var(--spacing-m);
  }
  .post__container {
    padding: var(--spacing-s);

    .post__content {
      .card__header {
        .card__shell-date {
          margin-right: 0;
        }
      }
      .card__content {
        padding-right: unset;
        mask-image: unset;
        h2 {
          margin-top: var(--spacing-xs);
          font-size: medium;
        }
        .card__text {
          display: none;
        }
      }
      .card__reactions {
        margin-top: var(--spacing-s);
        div {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
    .post__comments {
      display: none;
    }
  }

  .post__container--expanded {
    padding: var(--spacing-m) var(--spacing-xxl);

    .post__content {
      flex: 2;
      .card__header {
        .card__shell-date {
          margin-right: var(--spacing-m);
        }
      }
      .card__content {
        padding-right: var(--spacing-l);
        h2 {
          font-size: x-large;
        }
        .card__text {
          display: block;
        }
      }
      .card__reactions {
        div {
          width: 3rem;
          height: 3rem;
        }
      }
    }
    .post__comments {
      display: flex;
    }
  }
}
