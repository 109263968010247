// TODO: convert to woffs
@import "~/src/scss/breakpoints";

// --font-text: "IBM Plex Mono";
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Press+Start+2P&display=swap");

// --font-stylized: "Press Start 2P";
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

body {
  margin: 0;
  font-family: var(--font-text), -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  overflow-y: scroll;
  transition: background-image 0.4s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  width: 100%;
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  text-align: center;
  font-family: var(--font-stylized);
  font-size: x-large;
  letter-spacing: var(--spacing-xxs);
  text-transform: uppercase;
  color: var(--color-text);
  text-shadow: -2px -2px 0 rgba(var(--color-dominant-base), 0.2), 2px -2px 0 rgba(var(--color-dominant-base), 0.2),
    -2px 2px 0 rgba(var(--color-dominant-base), 0.2), 2px 2px 0 rgba(var(--color-dominant-base), 0.2);
  transition: color 0.4s, text-shadow 0.4s;

  @media (max-width: $breakpoint-small) {
    font-size: large;
  }
}

h3 {
  font-size: large;
  font-weight: bold;
  margin-top: 0;
}

button {
  text-transform: uppercase;
  font-weight: bold;
  font-size: small;
  border: 2px solid var(--color-card-text);
  border-radius: var(--border-radius);
  padding: var(--spacing-xxs) var(--spacing-m);
  background-color: var(--color-button-text);
  color: var(--color-card-text);
  outline: unset;
  &:hover {
    cursor: pointer;
    color: var(--color-button-text);
    background: var(--color-button-background);
  }
}

input {
  font-family: var(--font-text);
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  border: 7px solid white;
  background-color: var(--bg-color);
  border-radius: 50%;
  outline: 4px solid white;
  margin-right: calc(var(--spacing-s) + 5px);
  margin-top: 10px;

  &:checked {
    border: 7px solid transparent;
    outline: 4px solid white;

    animation: radio-check;
    animation-duration: 0.1s;
    animation-iteration-count: 2;
    animation-direction: alternate;
    animation-timing-function: linear;
  }

  &:hover {
    cursor: pointer;
  }
}

input[type="button"] {
  text-transform: uppercase;
  font-size: large;
  border: 1px solid var(--color-text);
  border-radius: var(--border-radius);
  padding: 2px var(--spacing-m);
  margin-bottom: var(--spacing-s);
  margin-left: var(--spacing-xxs);
  margin-right: var(--spacing-xxs);
  background: black;
  color: white;
}

@keyframes radio-check {
  0% {
    outline-offset: 0;
  }
  100% {
    outline-offset: 1px;
  }
}

.disable-scroll {
  position: fixed;
  overflow-y: scroll;
  left: 0;
  right: 0;
}

.no-hardware-acceleration {
  * {
    backdrop-filter: none !important;
  }
}

// Temporary place for css vars. Will move them to a separate file once the basics are set up
:root {
  --spacing-xxl: 6rem;
  --spacing-xl: 4rem;
  --spacing-l: 2rem;
  --spacing-m: 1rem;
  --spacing-s: 0.6rem;
  --spacing-xs: 0.4rem;
  --spacing-xxs: 0.2rem;

  --border-radius: 1.6rem;

  --font-text: "IBM Plex Mono";
  --font-stylized: "Press Start 2P";

  --color-primary-base: 247, 139, 5;
  --color-primary: rgba(var(--color-primary-base), 1);

  --header-height: 80px;
  --dock-height: 120px;
}

[theme="dark"] {
  --color-background-base: 0, 0, 0;
  --color-text-base: 255, 255, 255;
  --color-text: rgba(var(--color-text-base), 1);
  --color-button-background: white;
  --color-button-text: black;
  --color-button-hover: #e6e6e6;
}
[theme="light"] {
  --color-background-base: 205, 205, 205;
  --color-text-base: 0, 0, 0;
  --color-text: rgba(var(--color-text-base), 1);
  --color-button-background: black;
  --color-button-text: white;
  --color-button-hover: #141414;
}

[card-theme="dark"] {
  --color-card-text: white;
  --color-card-background-base: 0, 0, 0;
  --color-card-background: rgba(var(--color-card-background-base), 0.8);
  --color-card-button-background: #f4f4f4;
  --color-card-button-text: black;
  --color-card-button-reaction: #f4f4f4;
}

[card-theme="light"] {
  --color-card-text: black;
  --color-card-background-base: 255, 255, 255;
  --color-card-background: rgba(var(--color-card-background-base), 0.8);
  --color-card-button-background: #141414;
  --color-card-button-text: white;
  --color-card-button-reaction: #f4f4f4;
}

.rehydrating {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-background);
}

#webpack-dev-server-client-overlay {
  display: none;
}

// Temp
.under-construction {
  background-color: rgba(255, 0, 0, 0.8);
  border: 1px solid red;
  color: white;
  text-align: center;
  font-size: x-small;
  font-weight: bold;
  border-radius: 4px;
  margin-top: var(--spacing-s);
}

.dev-note {
  background-color: rgba(255, 255, 0, 0.8);
  border: 1px solid yellow;
  color: black;
  text-align: center;
  font-size: x-small;
  font-weight: bold;
  border-radius: 4px;
  margin-top: var(--spacing-s);
}
