.card__post-comment {
  position: relative;
  display: flex;
  padding-top: var(--spacing-s);
  margin-top: var(--spacing-s);
  background: var(--color-card-background-base);
  border-radius: 12px;
  input {
    border: unset;
    color: var(--color-card-text);
    border-bottom: 1px solid var(--color-card-text);
  }
  input[type="text"] {
    width: 100%;
    background: unset;
    font-size: medium;
    padding-right: 130px;
    padding-bottom: 8px;
    &:focus-visible {
      outline: unset;
    }
  }
  input[type="button"] {
    position: absolute;
    right: 0;
    background: var(--color-card-button-text);
    border-radius: var(--border-radius);
    border: 2px solid var(--color-card-text);
    padding: var(--spacing-xxs) var(--spacing-l);
    color: var(--color-card-text);
    font-size: small;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      background: var(--color-card-button-background);
      color: var(--color-card-button-text);
    }
  }

  &.card__post-comment--reply {
    width: 100%;
    height: 0;
    overflow: hidden;
    animation: open-reply-input 0.2s ease forwards;

    input[type="text"] {
      border-bottom: unset;
    }
    input[type="button"] {
      right: 26px;
    }
  }
}

.card__post-comment--disabled {
  opacity: 0.3;
  pointer-events: none;
  &:hover {
    background: var(--color-card-button-text);
  }
}

@keyframes open-reply-input {
  0% {
    height: 0;
  }
  100% {
    height: 30px;
  }
}
