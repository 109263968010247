@import "~/src/scss/breakpoints";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  // max-width: 1200px;
  height: 100%;
  margin: auto;
  box-sizing: border-box;

  @media (max-width: $breakpoint-medium) {
    padding: var(--spacing-s);
  }
}

.content {
  position: relative;
  margin: auto;
  margin-top: 80px;
  width: 86%;
  height: 100%;
  padding: 0 var(--spacing-m);
  box-sizing: border-box;
  @media (max-width: $breakpoint-large) {
    width: 96%;
  }
  @media (max-width: $breakpoint-small) {
    width: 100%;
    padding: 0;
  }
}

.app {
  height: 100vh;
  margin: auto;
}
