.settings__content {
  position: relative;
  box-sizing: border-box;
  padding: 0 var(--spacing-l);
  text-align: center;

  h2 {
    font-family: var(--font-stylized);
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
  }
}

.settings__tabs {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: var(--spacing-xl);
  user-select: none;
}

.settings__tab {
  text-transform: uppercase;
  font-weight: bold;
  font-size: small;
  border: 2px solid var(--color-text);
  border-radius: var(--border-radius);
  padding: var(--spacing-xxs) var(--spacing-m);
  margin-bottom: var(--spacing-s);
  margin-left: var(--spacing-xxs);
  margin-right: var(--spacing-xxs);

  &:hover {
    cursor: pointer;
    background: white;
    color: black;
  }
}

.settings__tab--active {
  background: white;
  color: black;
  &:hover {
    cursor: default;
  }
}

.settings__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.settings__group {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--spacing-m);
}

.settings__group-title {
  display: flex;
  font-size: larger;
  text-transform: uppercase;
  margin-bottom: var(--spacing-m);
  justify-content: flex-start;
}

.settings__group-subtitle {
  display: flex;
  font-size: medium;
  margin-bottom: var(--spacing-l);
  justify-content: flex-start;
  text-align: left;
}

.settings__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: var(--spacing-l);
}

.settings__row-title {
  display: flex;
  font-size: larger;
  flex: 2;
  margin-right: auto;
}

.settings__row-title--uppercase {
  @extend .settings__row-title;
  text-transform: uppercase;
}

.settings__row-action {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-right: auto;
}

.settings__row-action--right {
  @extend .settings__row-action;
  flex: unset;
  margin-right: 0;
  margin-left: auto;
}
