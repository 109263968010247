@import "~/src/scss/breakpoints";

.search-bar {
  background-color: var(--color-button-background);
  color: var(--color-button-text);
  border-radius: var(--border-radius);
  width: 100%;
  max-width: 400px;
  height: 36px;
  padding: 0 10px;
  line-height: 30px;
  align-items: center;
  display: flex;
  flex-direction: row;
  transition: width 0.2s;
  outline: 2px solid rgba(var(--color-dominant-base), 0.2);
  transition: background-color 0.4s, color 0.4s, outline 0.4s, opacity 0.4s;

  svg {
    flex-shrink: 0;
    stroke: var(--color-button-text);
  }

  input {
    width: 100%;
    border: unset;
    outline: unset;
    margin-left: var(--spacing-xs);
    font-size: medium;
    background: transparent;
    color: var(--color-button-text);
    @media (max-width: $breakpoint-xsmall) {
      display: none;
    }
  }

  .input-reset {
    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: $breakpoint-xsmall) {
    //width: 16px;
    //margin-left: auto;
  }
}

.search-bar--hidden {
  opacity: 0;
  pointer-events: none;
}

// ****************************************************************************
// search-popup
// ****************************************************************************

.search-popup {
  z-index: 999;
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  color: white;
  background: rgba(var(--color-card-background-base), 0.8);
}

.search-popup__content {
  width: min(80vw, 600px);
  margin: auto;
  background: black;
  border: 3px solid;
  border-radius: var(--border-radius);
  padding: var(--spacing-s) var(--spacing-m);
}

.search-popup__section {
  margin-bottom: var(--spacing-m);
  min-height: 3em;
}

.search-popup__section-title {
  opacity: 0.5;
  display: flex;

  label {
    font-size: small;
  }

  hr {
    flex-grow: 1;
    border: none;
    border-top: 1px solid;
    margin-left: 10px;
  }
}

.search-popup__header {
  padding: var(--spacing-s);
  position: relative;
  min-height: 12px; // account for spinner and "search-mode"
}

.search-popup__footer {
  text-align: right;
  font-size: small;
  opacity: 0.5;
}

.search-mode {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;

  .switch {
    transform: scale(0.5);
  }
}

.search-mode__text {
  font-size: small;
  opacity: 0.7;
}

.search-mode__text--active {
  opacity: 1;
}

.post-preview {
  background: #222222;
  display: flex;
  padding: var(--spacing-xxs);
  margin-bottom: var(--spacing-s);
}

.post-preview__thumb {
  width: 80px;
  height: 45px;
  margin-right: var(--spacing-s);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-sizing: border-box;
    border: 1px solid gray;
  }
}

.post-preview__author {
  font-size: small;
  opacity: 0.7;
}
