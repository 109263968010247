.comment {
  display: flex;
  flex-flow: row;
  margin-top: var(--spacing-m);

  p {
    font-size: small;
    margin-top: var(--spacing-xs);
    margin-bottom: 0;
    word-break: break-word;
  }
}

.comment__author-thumb {
  border-radius: 50%;
  margin-right: var(--spacing-s);
  width: 2.5em;
  height: 2.5em;
}

.comment__text {
  width: 100%;
}

.comment__author-name {
  display: block;
  font-weight: bold;
  font-size: small;
  align-self: flex-start;
  display: inline-block;
  &:hover {
    color: rgba(var(--color-text-base), 0.7);
  }
}

.comment__date {
  color: #a8a8a8;
  font-size: x-small;
  display: inline-block;
  margin-left: var(--spacing-s);
}

.comment__actions {
  display: flex;
  margin-top: var(--spacing-s);
  justify-content: flex-end;
  align-items: center;
}

.comment__action {
  display: flex;
  margin-left: var(--spacing-m);
  align-items: center;
  font-size: small;

  .icon {
    width: 14px;
    margin-right: var(--spacing-s);
    stroke: transparent;
    fill: var(--color-text);
  }
}

.comment__action--reply {
  margin-left: auto;
  &:hover {
    cursor: pointer;
    color: rgba(var(--color-text-base), 0.7);
    .icon {
      fill: rgba(var(--color-text-base), 0.7);
    }
  }
}

.comment__action--rate {
  justify-content: flex-end;
  min-width: 80px;

  .icon {
    &:hover {
      cursor: pointer;
      fill: red;
    }
  }

  .icon--dislike {
    margin-left: var(--spacing-s);
    margin-right: 0;
  }
}

.comment__add-reply {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid var(--color-card-text);
}

.comment__cancel-reply {
  text-align: center;
  align-items: center;
  color: var(--color-card-button-background);
  background: var(--color-card-button-text);
  border: 2px solid var(--color-card-button-background);
  font-size: medium;
  font-weight: bold;
  text-transform: uppercase;
  width: 23px;
  height: 23px;
  font-size: 16px;
  line-height: 21px;
  vertical-align: middle;
  flex-shrink: 0;
  margin-bottom: 3px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background: var(--color-card-button-background);
    color: var(--color-card-button-text);
  }
}

.comment__reply-count {
  font-weight: bold;
  font-size: small;
  justify-content: center;
  height: 100%;
  justify-self: center;
  margin-right: auto;

  &:hover {
    cursor: pointer;
    color: rgba(var(--color-text-base), 0.7);
  }
}

.reply-target {
  display: flex;
  max-width: 100%;
  flex: 1;
  margin-top: var(--spacing-s);
  color: #a8a8a8;
  overflow: hidden;
}

.reply-target__data {
  display: inline-flex;
  flex-direction: row;
  max-width: 100%;
  margin-left: var(--spacing-xs);
  font-size: small;
  font-style: italic;
  background: rgba(#a8a8a8, 0.1);
  padding: 3px 6px;
  border-radius: var(--border-radius);
}

.reply-target__avatar {
  border-radius: 50%;
  width: 2ch;
  height: 2ch;
}

.reply-target__author-name {
  flex-shrink: 0;
  max-width: 100px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 var(--spacing-xxs);
}

.reply-target__message {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
