@import "~/src/scss/breakpoints";

.dock {
  position: fixed;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 86%;
  background: black;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  height: 67px;
  bottom: 0;
  left: 0;
  right: 0;
  border: 3px solid var(--color-text);
  border-bottom: 0;
  transition: height 0.4s, padding 0.2s;
  box-sizing: border-box;
  z-index: 999;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-card-text);
  }

  &::-webkit-scrollbar-corner {
    background: unset;
  }

  @media (max-width: $breakpoint-large) {
    width: calc(100% - var(--spacing-m) * 2);
  }

  @media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
    padding: var(--spacing-s) var(--spacing-l);
  }
  @media (max-width: $breakpoint-medium) {
    padding: var(--spacing-s) var(--spacing-m);
  }
}

.dock--expanded {
  height: 40%;
  overflow-y: scroll;

  .icon--arrow_up {
    transform: rotateX(180deg);
  }
  .dock__row-title {
    margin-top: var(--spacing-m);
    margin-bottom: var(--spacing-m);
    font-size: large;
  }

  .dock__tabs__container {
    display: inline-block;
  }

  .dock__row {
    .dock__tile {
      display: flex !important;
    }
    @media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
      grid-template-columns: repeat(auto-fit, minmax(calc(100% / 5 - var(--spacing-m)), 1fr));
      .dock__tile:nth-child(n + 11):not(:last-child) {
        display: none;
      }
    }
    @media (max-width: $breakpoint-small) {
      grid-template-columns: repeat(auto-fit, minmax(calc(100% / 5 - var(--spacing-m)), 1fr));
      .dock__tile:nth-child(n + 14):not(:last-child) {
        display: none !important;
      }
    }
  }

  .dock__row--top {
    background: black;
    position: sticky;
    margin-bottom: 0;
    .dock__tile {
      margin-bottom: 0;
    }
  }
}

.dock__row-title {
  font-weight: bold;
  color: white;
  font-size: 0;
  // margin-left: calc(var(--spacing-m) * -1);
  margin-left: var(--spacing-s);
  text-transform: uppercase;
  transition: font-size 0.2s, margin 0.2s;

  @media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
    margin-left: calc(var(--spacing-m) * -1);
  }
  @media (max-width: $breakpoint-small) {
    margin-left: 0;
  }
}

.dock__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(100% / 12 - var(--spacing-s)), 1fr));
  gap: var(--spacing-s);
  width: 100%;
  margin-bottom: var(--spacing-l);
  box-sizing: border-box;
  padding: var(--spacing-s) var(--spacing-l);

  .dock__tile:last-child {
    grid-column-end: -1;
  }

  @media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 7 - var(--spacing-m)), 1fr));
    .dock__tile:nth-child(n + 7):not(:last-child) {
      display: none;
    }
  }
  @media (max-width: $breakpoint-small) {
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 7 - var(--spacing-m)), 1fr));
    .dock__tile:nth-child(n + 5):not(:last-child) {
      display: none;
    }
  }
}

.dock__row--top {
  position: absolute;
  top: 0;
  padding-top: 29px;

  @media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
    width: calc(100% - var(--spacing-m) * 2);
  }

  @media (max-width: $breakpoint-medium) {
    width: calc(100% - var(--spacing-m) * 2);
    .dock__tile:nth-child(n + 7):not(:last-child) {
      display: none !important;
    }
  }
}

.dock__tile {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: white;
  flex: 1;
  flex-basis: 0;
  flex-grow: 1;
  margin-bottom: var(--spacing-m);

  img {
    width: 1.8em;
    height: 1.8em;
    border-radius: 50%;
  }
}

.dock__tile-title {
  font-size: small;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  margin-bottom: var(--spacing-xxs);
}

.dock__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  align-self: flex-start;
  background-color: white;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  transform: rotateX(0deg);
  transition: transform 0.4s;
  margin-bottom: 40px;

  svg {
    stroke: none;
    fill: black;
  }

  &:hover {
    cursor: pointer;
  }
}

.dock__tabs__container {
  position: absolute;
  left: 20%;
  right: 20%;
  padding-top: 29px;
  display: none;
}

.dock__tabs {
  border-radius: 12px;
  display: flex;
  border: 3px solid white;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.dock__tab {
  color: white;
  display: flex;
  padding: var(--spacing-xs);
  font-size: small;
  flex: 1;
  justify-content: center;
  align-items: center;
  &:nth-of-type(2) {
    border-left: 3px solid white;
    border-right: 3px solid white;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
}
.dock__tab--active {
  background: white;
  color: black;
  &:hover {
    background: white;
    color: black;
    cursor: default;
  }
}
