.dev-page-bg {
  // Not allowed to change dialog__content height, so just match it...
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: var(--border-radius);
  border: 3px solid var(--color-text);
  padding: 0 var(--spacing-m);
}

.dev-page {
  background-color: var(--color-card-background);
  color: var(--color-card-text);

  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin: var(--spacing-s) 0;
  padding: var(--spacing-l);

  max-height: 90%;
  overflow: auto;

  select {
    font-size: medium;
    border: 3px solid var(--color-text);
    border-radius: var(--border-radius);
    padding: var(--spacing-xxs) var(--spacing-s);
  }

  a {
    color: orange;
  }
}

.dev-page__row {
  display: flex;
  align-items: center;
  padding: var(--spacing-m);
  border-bottom: 1px solid #404040;

  & > * {
    margin: 0 var(--spacing-s);
  }
}

.dev-page__row--no-sep {
  border-bottom: none;
}

.dev-page__row--tight {
  padding: var(--spacing-xs) var(--spacing-m);
}

.dev-page__clear {
  align-self: flex-start;
}

.dev-page__log {
  padding: var(--spacing-m);
  background-color: #353535;
  color: greenyellow;
  white-space: pre-wrap;
  font-size: small;
  border-radius: var(--border-radius);
}

.dev-page__hints {
  background-color: #353535;
  font-size: small;
}

.dev-page__account {
  border-bottom: 1px solid #404040;
}

.dev-page__edit {
  display: flex;
  flex-direction: column;
  margin: 0 var(--spacing-m);
  padding: var(--spacing-m);

  button {
    margin-top: var(--spacing-s);
    align-self: flex-start;
  }
}

.dev-page__profile-fields {
  display: flex;
  margin-bottom: var(--spacing-xs);

  label {
    width: 12ch;
  }
  input {
    width: 60ch;
  }
}

.dev-page__app-version {
  text-align: center;
}
