.oembed {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 8ch;

  .embedly-embed {
    border-radius: var(--border-radius);
    max-width: 100%;
    height: unset;
    aspect-ratio: 16/9;
  }
}

.oembed__spinner {
  align-self: center;
}

.oembed__error {
  padding: 0 var(--spacing-m);
}

.oembed__link-container {
  display: flex;
  width: 100%;
  max-height: 120px;
  border: 3px solid var(--color-text);
  border-radius: var(--border-radius);
  overflow: hidden;

  &:hover {
    border: 3px solid rgba(var(--color-text-base), 0.7);
    .oembed__link-title {
      color: rgba(var(--color-text-base), 0.7);
    }
  }
}

.oembed__link-thumbnail {
  width: 213px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: unset !important;
    aspect-ratio: unset !important;
  }
}

.oembed__link-details {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-s);

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .oembed__link-title {
    color: var(--color-card-text);
    font-weight: bold;
  }

  .oembed__link-url {
    color: gray;
    font-size: x-small;
    margin-bottom: var(--spacing-xs);
  }

  .oembed__link-description {
    font-size: x-small;
  }
}

.oembed__debug {
  font-size: x-small;
  color: pink; // var(--color-error);
}
