@import "~/src/scss/breakpoints";

.posts-filter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--spacing-m);

  @media (max-width: $breakpoint-small) {
    justify-content: center;
    margin-bottom: var(--spacing-l);
  }
}

.posts-filter__entry {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-button-text);
  color: var(--color-button-background);
  border: 3px solid var(--color-button-background);
  padding: var(--spacing-xs) var(--spacing-l);
  margin-left: var(--spacing-s);
  border-radius: var(--border-radius);
  text-transform: uppercase;
  font-family: var(--font-text);
  font-size: small;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background: var(--color-button-background);
    color: var(--color-button-text);
  }

  @media (max-width: $breakpoint-small) {
    flex: 1;
    &:first-of-type {
      margin-left: 0;
    }
  }
}

.posts-filter__entry--active {
  background: var(--color-button-background);
  color: var(--color-button-text);
  &:hover {
    cursor: default;
  }
}
