.landing__content {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  // padding: 0 var(--spacing-l);
  border: 3px solid var(--color-card-text);
  text-align: center;
  overflow: hidden;
}

.landing__card {
  max-width: 100%;

  input[type="button"],
  button {
    text-transform: uppercase;
    font-weight: bold;
    font-size: x-small;
    font-family: var(--font-stylized);
    border: 1px solid var(--color-text);
    border-radius: var(--border-radius);
    padding: var(--spacing-s) var(--spacing-m);
    margin-top: var(--spacing-l);
    background: black;
    color: white;
    &:hover {
      cursor: pointer;
      background: white;
      color: black;
    }
  }

  button {
    font-size: small;
    width: 200px;
    padding: var(--spacing-m) var(--spacing-m);
  }

  input[type="text"] {
    border: 3px solid var(--color-text);
    box-sizing: border-box;
    padding: var(--spacing-s) var(--spacing-s);
    width: 100%;
  }

  h2 {
    font-family: var(--font-stylized);
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
  }

  p {
    margin-bottom: var(--spacing-l);
  }
}

.landing__options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing__option {
  text-transform: uppercase;
  font-weight: bold;
  font-size: small;
  border: 1px solid var(--color-text);
  border-radius: var(--border-radius);
  padding: var(--spacing-m) var(--spacing-m);
  margin-top: var(--spacing-l);
  width: 200px;
  &:hover {
    cursor: pointer;
    background: white;
    color: black;
  }
}

.landing__stored {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-xl);
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    // text-transform: uppercase;
    font-weight: bold;
    font-size: small;
    background: rgba(255, 255, 255, 0.1);
    border-radius: var(--border-radius);
    padding: var(--spacing-s) var(--spacing-s);
    margin-bottom: var(--spacing-m);
    min-width: 160px;
  }
  .icon {
    overflow: visible;
    stroke: transparent;
  }
}

.landing__group {
  display: flex;
  width: 100%;
  margin-bottom: var(--spacing-l);

  .landing__value {
    width: 100px;
    text-transform: uppercase;
    margin-top: var(--spacing-s);
  }

  .landing__action {
    width: 100%;
    text-align: left;
    .landing__action-description {
      font-size: small;
      margin-top: var(--spacing-s);
    }
  }
}

.landing__errors {
  color: red;
  margin-top: var(--spacing-m);
}
