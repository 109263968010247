@import "~/src/scss/breakpoints";

.header {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: var(--header-height);
  justify-content: center;
  z-index: 99;
}

.header__content {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  width: 86%;
  margin: auto;
  padding: 20px 0 40px 0;
  z-index: 2;

  align-items: center;
  background: white;

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  overflow-wrap: anywhere;
  -webkit-mask-image: -webkit-gradient(linear, left 65%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  mask-image: -webkit-gradient(linear, left 65%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));

  transition: background-image 0.4s ease-in-out;

  a {
    text-decoration: none;
  }

  .header__item-left {
    display: flex;
    min-width: 320px;
    @media (max-width: $breakpoint-small) {
      min-width: 200px;
      margin-left: var(--spacing-s);
    }
  }

  .header__item-center {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 var(--spacing-m);
  }

  .header__item-right {
    min-width: 320px;
    @media (max-width: $breakpoint-small) {
      min-width: 200px;
      margin-right: var(--spacing-s);
    }
  }

  @media (max-width: $breakpoint-large) {
    width: calc(100% - var(--spacing-m) * 2);
  }

  @media (max-width: $breakpoint-medium) {
    width: 100%;
    max-width: 100%;
    .header__item-left {
      // flex:1;
      // margin-left: var(--spacing-s);
      margin-right: auto;
    }
    .header__item-center {
      display: none;
    }
    .header__item-right {
      flex: 1;
      margin-left: auto;
      // margin-right: var(--spacing-s);
    }
  }
}

.header__logo {
  font-family: var(--font-stylized);
  text-decoration: none;
  color: var(--color-primary);
  font-size: x-large;
  backdrop-filter: none;
  text-shadow: -2px -2px 0 rgba(var(--color-dominant-base), 0.2), 2px -2px 0 rgba(var(--color-dominant-base), 0.2),
    -2px 2px 0 rgba(var(--color-dominant-base), 0.2), 2px 2px 0 rgba(var(--color-dominant-base), 0.2);
}
