@import "~/src/scss/breakpoints";

.post-form__container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  padding: 0 var(--spacing-l);
  border: 3px solid var(--color-card-text);
  background: black;
  color: white;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: white;
  }

  &::-webkit-scrollbar-corner {
    background: unset;
  }

  input[type="text"],
  textarea {
    border: 3px solid var(--color-text);
    padding: var(--spacing-s) var(--spacing-s);
    width: 100%;
    box-sizing: border-box;
  }
  input[type="submit"] {
    border-radius: var(--border-radius);
    padding: var(--spacing-xs);
    margin-left: auto;
    font-size: medium;
    font-family: var(--font-stylized);
    background-color: white;
    padding: var(--spacing-s) var(--spacing-m);
  }
  textarea {
    height: 140px;
  }

  label {
    display: block;
    width: 100%;
    text-align: center;
    font-size: x-small;
  }
}

.post-form__group {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: var(--spacing-l);

  label {
    width: 160px;
    font-size: large;
    text-align: left;
  }

  select {
    width: 100%;
    padding: var(--spacing-s) var(--spacing-s);
  }

  .invalid-feedback {
    display: block;
    margin-top: var(--spacing-s);
    font-size: x-small;
    color: red;
  }

  .oembed {
    padding: var(--spacing-xs);
    border: 2px dashed white;
    .oembed__link-details {
      padding-top: 0;
      .oembed__link-title,
      .oembed__link-description {
        color: white;
      }
    }
    a {
      text-decoration: none;
    }
  }
}

.post-form__header {
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  height: 50px;
  padding-top: var(--spacing-l);
  background: black;
  h1 {
    margin-top: 0;
    text-shadow: unset;
    color: white;
  }
  img {
    position: absolute;
    top: calc(var(--spacing-m) + var(--spacing-s));
    right: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
  }
}

.post-form__value {
  width: 100%;
}

.post-form__tabs {
  position: sticky;
  top: 82px;
  background: black;
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--spacing-l);
  margin-bottom: 3px;
}

.post-form__tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: rgba(255, 255, 255, 0.2);
  justify-content: center;
  border-radius: var(--border-radius);
  margin-right: var(--spacing-s);
  border: 3px solid white;

  @media (min-width: $breakpoint-large) {
    font-size: medium;
  }
  @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
    font-size: small;
  }
  @media (max-width: $breakpoint-medium) {
    font-size: xx-small;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover:not(.post-form__tab--active) {
    cursor: pointer;
    background: rgba(122, 122, 122, 0.4);
  }

  .icon-wrapper {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background-color: white;
    padding: 0.2rem;
    border-radius: 50%;
    margin-right: var(--spacing-s);
    outline: 3px solid white;
    .icon {
      stroke: black;
      overflow: visible;
    }
  }
  label {
    margin-right: auto;
    font-size: medium;
    font-weight: bold;
    width: auto;
  }
}

.post-form__tab--active {
  background: white;
  color: black;
}

.post-form__upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px dotted white;
  width: 100%;
  min-height: 140px;
  // border-radius: var(--border-radius);

  .icon {
    fill: white;
    margin-bottom: var(--spacing-s);
  }
}

.post-form__upload-image {
  width: 100%;
}

.post-form__thumbnail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  border: 3px solid var(--color-text);
  width: 100%;
  // min-height: 100px;
  border-radius: var(--border-radius);

  .icon--picture {
    width: 120px;
    height: 120px;
    stroke: white;
    fill: white;
    stroke-width: 0.5px;
    stroke-linecap: square !important;
    stroke-linejoin: miter !important;
    margin-bottom: -10px;
  }
}

.post-form__thumbnail-preview {
  margin-right: var(--spacing-l);
}

.post-form__thumbnail-settings {
  position: relative;
  width: 100%;
  height: 100%;
  label {
    display: block;
    width: 100%;
  }

  .post-form__thumbnail-source {
    // position: absolute;
    display: block;
    //bottom: var(--spacing-m);
    padding: var(--spacing-m) 0;
    width: 100%;

    input {
      height: 4ch;
      width: 100%;
    }
  }
}

.post-form__thumbnail-browse {
  button {
    text-transform: uppercase;
    font-weight: bold;
    font-size: small;
    border: 2px solid white;
    border-radius: var(--border-radius);
    padding: var(--spacing-xxs) var(--spacing-m);
    margin-left: auto;
    margin-bottom: var(--spacing-s);
    background: black;
    color: white;
    width: 200px;
  }
}

.post-form__error {
  color: lightcoral;
}
