.recycleBinPage {
  margin-top: var(--spacing-xl);

  ul {
    padding: var(--spacing-s);
    list-style-type: none;
  }

  .item {
    display: flex;
    flex-direction: row;
    margin: 0 0 var(--spacing-m) 0;
  }

  .item--restoring {
    opacity: 0.6;
  }

  .item__thumb {
    height: 100px;
    aspect-ratio: 16 / 9;
    background: black;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .item__meta {
    margin: 0 var(--spacing-m);
    width: 40ch;
  }

  .item__title {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item__ts {
    opacity: 0.8;
    font-size: small;
  }

  .item__id {
    font-size: x-small;
    opacity: 0.7;
    margin-top: var(--spacing-xs);
  }

  .item__action {
  }
}

.recycleBinPage__error {
  color: red;
  background: rgba(255, 0, 0, 0.1);
  padding: var(--spacing-s);
}

.recycleBinPage__empty {
  padding: var(--spacing-s);
  color: var(--color-text);
}
