.menu {
  // width: 220px;
  // height: 160px;
  background: var(--color-button-background);
  border: 3px solid rgba(var(--color-dominant-base), 0.2);
  color: var(--color-button-text);
  position: fixed;
  z-index: 99999999;
  mask-composite: source-over;
  border-radius: var(--border-radius);
  padding: var(--spacing-m) 0;
  box-sizing: border-box;
  font-size: small;
  animation: open-menu 0.2s ease-in-out forwards;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--color-button-background);
  }
  &::after {
    content: "";
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid rgba(var(--color-dominant-base), 0.2);
  }
}

.menu-entry {
  user-select: none;
  padding: 5px var(--spacing-m);
  &:hover {
    cursor: pointer;
    background-color: var(--color-button-hover);
  }
}

.menu-entry--disabled {
  opacity: 0.3;
  pointer-events: none;
}

.menu-entry__title {
  font-weight: bold;
  font-size: small;
}

.menu-entry__subtitle {
  font-size: x-small;
}

@keyframes open-menu {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}
