.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: white;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px white;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 18px;
    width: 60px;
    // z-index:1;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: black;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  label {
    position: absolute;
    color: black;
    top: 14px;
    font-size: 7px;
    font-weight: bold;
    pointer-events: none;
    font-family: var(--font-stylized);
  }

  .on {
    left: 9px;
  }

  .off {
    right: 4px;
  }
}
